<template>
    <div >
        <!-- ini adalah component Tambah ATK Masuk -->
        
        <!-- <template-form :initialFields="fields" >
        </template-form> -->                
        <template-form v-if="!isLoading" :initialFields="fields" :onSubmit="onSubmit" :update="true">
            <template v-slot:notification>
                <message :type="ntf.type" :status="ntf.status" :message="ntf.message" />
            </template>
		</template-form>
    </div>
</template>

<script>
import Form from './Form.vue';
import { defaultFields } from "./helpers";
    export default {
    data() {
		/* return {
            fields: defaultFields(),
		} */
         return {
                isLoading: false,
                fields: defaultFields(),
              /*   fields: {
                    name: '',
                    address: '',
                    phone: ''
                }, */
                ntf: {
                    type: 1,
                    status: false,
                    message: ''
                }
            }
	},
    components: {
        'template-form': Form 
	},
          beforeMount() {
            this.getData(this.$route.params.id)
            /* console.log('create');
            console.log(this.fields); */
        },
    methods: {
		async onSubmit1(fields) {
			this.isLoading = true;
            console.log(fields);
            this.fields = defaultFields();
			
            
			this.isLoading = false;
		},
        async getData(id) {
                this.isLoading = true;
                try {
                    const response = await this.apiGets(`atk_in/`+id);
                    //console.log(this.fields);
                    //this.fields = { ...response.data.data};
                    var tampung = { ...response.data.header};
                    this.fields.notrans=tampung.masuk_h_no;
                    this.fields.tgl=this.formatInputDate(tampung.masuk_date);
                    this.fields.id=tampung.id;
                    this.fields.tipe=parseInt(tampung.masuk_h_tipe);
                    this.fields.ket=tampung.masuk_h_note;
                    //this.fields.detail={ ...response.data.header.detail};
                    this.fields.detail=tampung.detail;
                    //dataku = response.data.data;
                    console.log(' edit get data');
                    //console.log(response);
                    console.log(this.fields);
                    
                } catch (error) {
                    /* this.$notify({
                        type: 'error',
                        title: error.message + '!',
                        text: defaultErrors(error.response.data.errors)
                    }) */
                    console.log(error);     
                }
                //console.log(this.fields);    
                this.isLoading = false;
            },
        async onSubmit(fields) {
                    this.isLoading = true;
                    console.log('mau submit');  
                    console.log(fields);                    
                    try {
                        //const response = await this.apiPosts(`atk_in/update/`, fields);
                        const response = await this.apiPosts(`atk_in/update/${this.$route.params.id}`, fields);
                        console.log(response);                        
                        if(response.status == 200) {
                            this.$router.push({
                                name: 'tran_atk_in'
                            });
                        }
                         
                    } catch (error) {
                        console.log(error);                                                
                    }
                    this.isLoading = false;
                },
        defaultFields
	}   
    }
</script>