/* const defaultFields1 = (user) => {
    var today = new Date();
    var start = new Date(today.getFullYear(), today.getMonth(), 1);
    return {
        "m_company_id": user.m_company_id?user.m_company_id:"0",
        "m_company": user.m_company_name?user.m_company_name:"ALL",
        "pr_rep_date1": vm.formatInputDate(start),
        "pr_rep_date2": vm.formatInputDate(today),
        "m_depo_id": user.m_depo_id?user.m_depo_id:"0",
        "m_depo": user.m_depo_name?user.m_depo_name:"ALL",
        "m_item_type_id": null,
        "m_item_type": "",
        "m_division_id": null,
        "m_division": "",
        "pr_rep_type": "SUMMARY",
        "t_pr_h": "",
        "t_pr_h_id": "",
        "m_item": null,
        "m_item_id": "",
        "pr_rep_status": "",
        "pr_rep_format": "PDF"
      };
}; */
//import moment from 'moment'
const defaultFields = () => {
	var today = new Date();
	var test =  vs.formatInputDate(today);
	//var test=1;
	console.log(today);
	console.log(test);
	return {
		//"tgl":  this.$vm.formatInputDate(today),
		//"tgl":  moment(today).format("YYYY-MM-DD"),
		//"tgl":  this.global.vm.formatInputDate(today),
		notrans: '',
		"tgl":  vs.formatInputDate(today),
		//"tgl2":  vm.tes,
		name: '',
		ket: '',
		qty: 0,
		price: 0,
		tipe: '',
		detail: []
	}
	
};

/* const defaultFields2 = () => ({
	name: '',
	qty: 0,
	price: 0,
	code: null,
	items: []
}) */
const defaultFieldItems = () => ({
	gdg_id: 0,
	item_id: 0,
	qty: 0,
	price: 0,
})

export {
    defaultFields,
	defaultFieldItems
}